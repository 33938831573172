import { NotificationModel } from '../../models';

export class LoadNotificationsList {
  static readonly type = '[Notification Details] Load Notification List';

  constructor(public payload?: NotificationModel[]) {}
}

export class MarkNotificationAsRead {
  static readonly type = '[Notifications] Mark Notification As Read';

  constructor(public id: number) {}
}

export class UpdateNotifications {
  static readonly type = '[Notifications] Update Notifications';

  constructor(public notifications: NotificationModel[]) {}
}

export class RedirectToPage {
  static readonly type = '[Notifications] Redirect To Page';

  constructor(public url: string) {}
}
