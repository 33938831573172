// TODO - It can be removed later. i.e., once API is ready
export const notificationsList = {
  data: {
    notifications: {
      data: [
        {
          id: 1,
          isRead: false,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Audit plan available',
          },
          title: 'Audit plan available',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: 'http://localhost:4200/contracts',
            },
          ],
        },
        {
          id: 2,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Contract',
          },
          title: 'Contract',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 3,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Audits management',
          },
          title: 'Audits management',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 4,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Findings management',
          },
          title: 'Findings management',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 5,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Financials',
          },
          title: 'Financials',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 6,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Certificates',
          },
          title: 'Certificates',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 7,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Schedule',
          },
          title: 'Schedule',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 8,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Organization settings',
          },
          title: 'Organization settings	',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 9,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Audit plan available',
          },
          title: 'Audit plan available',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 10,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Findings management',
          },
          title: 'Findings management',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 11,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Schedule',
          },
          title: 'Schedule',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
        {
          id: 12,
          iconTooltip: {
            displayIcon: true,
            iconClass: 'pi pi-circle-fill',
            iconPosition: 'prefix',
            tooltipMessage: 'Certificates',
          },
          title: 'Certificates',
          message:
            'Your auditor has shared the audit plan for ISO 9001:2017 and ISO 14001:2018.',
          receivedon: '03.10.2024',
          actions: [
            {
              actionType: 'redirect',
              iconClass: 'pi-angle-right',
              label: 'angle-right',
              url: '',
            },
          ],
          isRead: false,
        },
      ],
    },
  },
};
