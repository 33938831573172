import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { notificationsList } from '../../../__mocks__';
import { NotificationModel } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class UnreadNotificationsService {
  getUnreadNotifications(): Observable<NotificationModel[]> {
    // TODO, will Replace this mock data with your actual HTTP call
    const mockData: NotificationModel[] =
      notificationsList.data.notifications.data.filter(
        (notification) => notification.isRead === false,
      );

    return of(mockData);
  }
}
