import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { notificationFilter } from '../../../__mocks__/notification-filter-list.mock';
import { NotificationFilterModel } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class NotificationFilterService {
  getNotificationFilterList(): Observable<NotificationFilterModel[]> {
    // TODO, will Replace this mock data with your actual HTTP call
    const mockData: NotificationFilterModel[] =
      notificationFilter.data.notificationFilter.data;

    return of(mockData);
  }
}
