import { Selector } from '@ngxs/store';

import { NotificationModel } from '../../models';
import {
  NotificationListState,
  NotificationsListStateModel,
} from '../notification-list.state';

export class NotificationListSelectors {
  @Selector([NotificationListState])
  static getNotifications(
    state: NotificationsListStateModel,
  ): NotificationModel[] {
    return state?.notifications || [];
  }
}
