import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

import { NotificationModel } from '../models';
import { UnreadNotificationsService } from '../services';
import { LoadUnreadNotifications } from './actions';

export interface UnreadNotificationsStateModel {
  notifications: NotificationModel[];
}

const defaultState: UnreadNotificationsStateModel = {
  notifications: [],
};

@State<UnreadNotificationsStateModel>({
  name: 'unreadNotifications',
  defaults: defaultState,
})
@Injectable()
export class UnreadNotificationsState {
  constructor(private unreadNotificationsService: UnreadNotificationsService) {}

  @Action(LoadUnreadNotifications)
  unreadNotificationList(ctx: StateContext<UnreadNotificationsStateModel>) {
    return this.unreadNotificationsService.getUnreadNotifications().pipe(
      tap((notifications: NotificationModel[]) => {
        ctx.patchState({ notifications });
      }),
    );
  }
}
