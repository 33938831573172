import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import {
  NotificationFilterModel,
  NotificationFilterParams,
} from '../../models/notification-filter.model';
import { LoadNotificationFilterList } from '../actions/notification-filter.action';
import { NotificationFilterSelectors } from '../selectors/notification-filter.selectors';

@Injectable()
export class NotificationFilterStoreService {
  constructor(private store: Store) {}

  get notificationFilterList(): Signal<NotificationFilterModel[]> {
    return this.store.selectSignal(
      NotificationFilterSelectors.NotificationFilterList,
    );
  }

  @Dispatch()
  loadNotificationFilterList = (params?: NotificationFilterParams) =>
    new LoadNotificationFilterList(params);
}
