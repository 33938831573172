import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, of, tap } from 'rxjs';

import { NotificationModel } from '../models/notification-list.model';
import { NotificationListService } from '../services';
import {
  LoadNotificationsList,
  MarkNotificationAsRead,
  RedirectToPage,
  UpdateNotifications,
} from './actions/notification-list.action';

export interface NotificationsListStateModel {
  notifications: NotificationModel[];
}

const defaultState: NotificationsListStateModel = {
  notifications: [],
};

@State<NotificationsListStateModel>({
  name: 'notificationList',
  defaults: defaultState,
})
@Injectable()
export class NotificationListState {
  constructor(private notificationListService: NotificationListService) {}

  @Action(LoadNotificationsList)
  loadNotificationList(ctx: StateContext<NotificationsListStateModel>) {
    return this.notificationListService.getNotificationList().pipe(
      tap((notifications: NotificationModel[]) => {
        ctx.patchState({ notifications });
      }),
    );
  }

  @Action(MarkNotificationAsRead)
  markNotificationAsRead(
    ctx: StateContext<NotificationsListStateModel>,
    action: MarkNotificationAsRead,
  ) {
    const state = ctx.getState();

    return this.notificationListService.updateNotification(action.id).pipe(
      tap((notificationList) => {
        ctx.patchState({
          ...state,
          notifications: notificationList,
        });
      }),
      catchError(() => {
        ctx.patchState({ notifications: [] });

        return of([]);
      }),
    );
  }

  @Action(UpdateNotifications)
  updateNotifications(
    ctx: StateContext<NotificationsListStateModel>,
    action: UpdateNotifications,
  ): void {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      notifications: action.notifications,
    });
  }

  @Action(RedirectToPage)
  redirectToPage(ctx: StateContext<any>, action: RedirectToPage): void {
    ctx.dispatch(new Navigate([action.url]));
  }
}
