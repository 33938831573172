import { Selector } from '@ngxs/store';

import { NotificationFilterModel } from '../../models/notification-filter.model';
import {
  NotificationFilterState,
  NotificationFilterStateModel,
} from '../notification-filter.state';

export class NotificationFilterSelectors {
  @Selector([NotificationFilterState])
  static NotificationFilterList(
    state: NotificationFilterStateModel,
  ): NotificationFilterModel[] {
    return state?.filterList || [];
  }
}
