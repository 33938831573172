import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, of, tap } from 'rxjs';

import { NotificationFilterModel } from '../models/notification-filter.model';
import { NotificationFilterService } from '../services/data-services/notification-filter-service/notification-filter.service';
import { LoadNotificationFilterList } from './actions/notification-filter.action';

export interface NotificationFilterStateModel {
  filterList: NotificationFilterModel[];
  isPreferenceSet: boolean;
}

const defaultState: NotificationFilterStateModel = {
  filterList: [],
  isPreferenceSet: false,
};

@State<NotificationFilterStateModel>({
  name: 'notificationFilter',
  defaults: defaultState,
})
@Injectable()
export class NotificationFilterState {
  constructor(private notificationFilterService: NotificationFilterService) {}

  @Action(LoadNotificationFilterList)
  loadNotificationFilterList(
    ctx: StateContext<NotificationFilterStateModel>,
    { params: _params }: LoadNotificationFilterList,
  ) {
    return this.notificationFilterService.getNotificationFilterList().pipe(
      tap((filterList) => {
        ctx.patchState({ filterList });
      }),
      catchError(() => {
        ctx.patchState({ filterList: [] });

        return of([]);
      }),
    );
  }
}
