import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { NotificationModel } from '../../models/notification-list.model';
import {
  LoadNotificationsList,
  MarkNotificationAsRead,
  RedirectToPage,
} from '../actions/notification-list.action';
import { NotificationListSelectors } from '../selectors/notification-list.selectors';

@Injectable()
export class NotificationListStoreService {
  constructor(private store: Store) {}

  get notificationList(): Signal<NotificationModel[]> {
    return this.store.selectSignal(NotificationListSelectors.getNotifications);
  }

  @Dispatch()
  loadNotificationList = (payload?: NotificationModel[]) =>
    new LoadNotificationsList(payload);

  @Dispatch()
  markNotificationAsRead = (id: number) => new MarkNotificationAsRead(id);

  @Dispatch()
  redirectToPage = (url: string) => new RedirectToPage(url);
}
