import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { notificationsList } from '../../../__mocks__/notification-list.mock';
import { NotificationModel } from '../../../models/notification-list.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationListService {
  getNotificationList(): Observable<NotificationModel[]> {
    // TODO, will Replace this mock data with your actual HTTP call
    const mockData: NotificationModel[] =
      notificationsList.data.notifications.data;

    return of(mockData);
  }

  updateNotification(id: number): Observable<NotificationModel[]> {
    // TODO, will call actual HTTP call after "updatedNotifications" method
    const mockData: NotificationModel[] =
      notificationsList.data.notifications.data;
    const updatedNotifications = mockData.map((notification) =>
      notification.id === id
        ? {
            ...notification,
            isRead: true,
            iconTooltip: {
              ...notification.iconTooltip,
              iconClass: 'read-notification',
            },
          }
        : notification,
    );

    return of(updatedNotifications);
  }
}
