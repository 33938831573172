import { Selector } from '@ngxs/store';

import {
  UnreadNotificationsState,
  UnreadNotificationsStateModel,
} from '../unread-notifications.state';

export class UnreadNotificationsSelectors {
  @Selector([UnreadNotificationsState])
  static getUnreadNotifications(state: UnreadNotificationsStateModel): number {
    return state?.notifications.length || 0;
  }
}
