// TODO - It can be removed later. i.e., once API is ready
export const notificationFilter = {
  data: {
    notificationFilter: {
      data: [
        {
          id: 'oiyi77s72yd',
          key: 'categories',
          options: [
            'ISO 14001:2015',
            'ISO 22000:2018',
            'ISO 27001:2013',
            'ISO 45001:2018',
            'ISO 540001:2018',
            'ISO 9001:2015',
          ],
          placeholderKey: 'notifications.notificationFilter.allCategories',
          selected: ['ISO 9001:2015'],
        },
        {
          id: 'jw21a1ixtqc',
          key: 'services',
          options: [
            'Cancelled',
            'Confirmed',
            'To Be Confirmed',
            'To Be Confirmed by DNV',
          ],
          placeholderKey: 'notifications.notificationFilter.allServices',
          selected: [],
        },
        {
          id: 'n7giszwq7ya',
          key: 'companies',
          options: ['Enrich Solar', 'Opentech'],
          placeholderKey: 'notifications.notificationFilter.allCompanies',
          selected: [],
        },
        {
          id: 'v55nz6tjwqa',
          key: 'sites',
          options: [
            'Enrich Solar Services Private Limited',
            'Opentech (China)',
            'Opentech (Estonia)',
            'Opentech (Germany)',
            'Opentech (Netherlands)',
            'Opentech (Russia)',
            'Opentech (Spain)',
            'Opentech (UK)',
          ],
          placeholderKey: 'notifications.notificationFilter.allSites',
          selected: [],
        },
      ],
    },
  },
};
